import React from 'react';
import { TokenManager } from '../../process/TokenManager.js';

export const ReadyItem = () => {
    React.useEffect(() => {
        if (!location.href.includes('/ready')) return;
        TokenManager.inspection().then(() => {
            const Data = TokenManager.get();
            if (Data.accessToken !== null) {
                location.href = '/user';
                return Promise.resolve();
            }
            return fetch('https://idportal.meigetsu.jp/app/auth', { credentials: 'omit' })
                .then(res => {
                    if (res.status === 200) return res.json();
                    else return res.text().then(text => Promise.reject(new Error(text)));
                })
                .then(data => {
                    sessionStorage.setItem('cdv', data.code_verify);
                    location.href = data.redirect_url;
                })
                .catch(er => console.error(er.message));
        }, [location.pathname]);
    });
    return (
        <div className="container">
            <p>サインインの準備をしています。しばらくお待ち下さい。</p>
        </div>
    );
};
