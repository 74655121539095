import React from 'react';
import { TokenManager } from '../../process/TokenManager.js';

async function CallbackProcess() {
    const queryParams = new URLSearchParams(location.search);
    const AuthCode = queryParams.get('code');
    const PKCEVerify = sessionStorage.getItem('cdv');
    if (!AuthCode || !PKCEVerify) location.href = '/';
    else {
        await fetch('https://idportal.meigetsu.jp/api/v2/auth/token', {
            method: 'POST',
            credentials: 'omit',
            body: JSON.stringify({
                grant_type: 'authorization_code',
                code: AuthCode,
                code_verifier: PKCEVerify,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                if (res.status !== 200) return res.text().then(txt => Promise.reject(new Error(txt)));
                return res.json();
            })
            .then(data => {
                return {
                    token_type: data.token_type,
                    access_token: data.access_token,
                    refresh_token: data.refresh_token,
                    expires_at: {
                        access_token: new Date(data.expires_at.access_token),
                        refresh_token: new Date(data.expires_at.refresh_token),
                    },
                };
            })
            .then(data => {
                TokenManager.set(data);
                location.href = '/user';
            });
    }
}

export const CallbackItem = () => {
    React.useEffect(() => {
        if (!location.href.includes('/callback')) return;
        CallbackProcess().catch(er => console.log(er.message));
    }, [location.pathname]);
    return (
        <div className="container">
            <p>必要な情報を収集中です</p>
        </div>
    );
};
