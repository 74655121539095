import React from 'react';
import { useParams } from 'react-router-dom';
import toastr from 'toastr';
import { TokenManager } from '../../process/TokenManager.js';
import '../../css/appedit.css';

export const AppEditItem = () => {
    const { appId } = useParams();
    if (appId == null) {
        location.href = '/application';
        return <p></p>;
    }
    const [state, setState] = React.useState({
        input: null,
        response: {
            client_secret: '',
        },
    });
    React.useEffect(() => {
        if (state.input != null) return;
        TokenManager.inspection().then(() => {
            const Token = TokenManager.get();
            if (Token.accessToken == null) {
                location.href = '/ready';
                return Promise.resolve();
            }
            return fetch('https://idportal.meigetsu.jp/api/v2/application/' + appId, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + Token.accessToken,
                },
            })
                .then(response => {
                    if (response.status !== 200) return response.text().then(text => Promise.reject(new Error(text)));
                    return response.json();
                })
                .then(json => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input = {
                        name: json.name,
                        description: json.description,
                        callback_url: json.callback_url,
                        privacy: json.privacy,
                        term: json.term,
                        regenerate_client_secret: false,
                    };
                    setState(CurrentState);
                })
                .catch(error => console.error(error.message));
        });
    });
    if (state.input == null)
        return React.createElement('div', { className: 'container' }, React.createElement('p', null, '読み込み中'));
    const EditMenu = React.createElement('dl', null, [
        React.createElement('dt', null, 'アプリケーションの名前'),
        React.createElement(
            'dd',
            null,
            React.createElement('input', {
                type: 'text',
                value: state.input.name,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.name = e.target.value;
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, 'アプリケーションの説明'),
        React.createElement(
            'dd',
            null,
            React.createElement('textarea', {
                className: 'app_detail',
                value: state.input.description,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.description = e.target.value.replaceAll(/\r\n|\n|\r/g, '\n');
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, 'コールバックURL'),
        React.createElement(
            'dd',
            null,
            React.createElement('input', {
                type: 'text',
                value: state.input.callback_url,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.callback_url = e.target.value;
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, 'プライバシーポリシー'),
        React.createElement(
            'dd',
            null,
            React.createElement('input', {
                type: 'text',
                value: state.input.privacy,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.privacy = e.target.value;
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, '利用規約'),
        React.createElement(
            'dd',
            null,
            React.createElement('input', {
                type: 'text',
                value: state.input.term,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.term = e.target.value;
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, 'クライアントシークレットの再生成'),
        React.createElement('dd', null, [
            React.createElement('input', {
                type: 'checkbox',
                checked: state.input.regenerate_client_secret,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.regenerate_client_secret = e.target.checked;
                    setState(CurrentState);
                },
            }),
            React.createElement('label', null, '再生成する'),
        ]),
        React.createElement('dd', null, [
            React.createElement('input', {
                type: 'submit',
                value: '更新',
                onClick: () => {
                    fetch('https://idportal.meigetsu.jp/api/v2/application/' + appId, {
                        method: 'PATCH',
                        body: JSON.stringify(state.input),
                        headers: {
                            Authorization: 'Bearer ' + TokenManager.get().accessToken,
                            'Content-Type': 'application/json',
                        },
                    }).then(response => {
                        if (response.status !== 200) return response.text().then(text => toastr.error(text));
                        toastr.success('アプリケーションの情報を更新しました');
                        if (state.input.regenerate_client_secret) {
                            return response.json().then(json => {
                                const CurrentState = Object.assign({}, state);
                                CurrentState.response.client_secret = json.client_secret;
                                setState(CurrentState);
                            });
                        }
                        return Promise.resolve();
                    });
                },
            }),
            React.createElement('input', {
                type: 'submit',
                value: '削除',
                onClick: () => {
                    fetch('https://idportal.meigetsu.jp/api/v2/application/' + appId, {
                        method: 'DELETE',
                        headers: {
                            Authorization: 'Bearer ' + TokenManager.get().accessToken,
                            'Content-Type': 'application/json',
                        },
                    }).then(response => {
                        if (response.status !== 200) return response.text().then(text => toastr.error(text));
                        toastr.success('アプリケーションを削除しました');
                        location.href = '/application';
                    });
                },
            }),
        ]),
        React.createElement(
            'dd',
            { style: { visibility: state.response.client_secret.length > 0 ? 'visible' : 'hidden' } },
            React.createElement('input', { type: 'text', value: state.response.client_secret, readOnly: true })
        ),
        React.createElement(
            'dd',
            { style: { visibility: state.response.client_secret.length > 0 ? 'visible' : 'hidden' } },
            React.createElement('input', {
                type: 'button',
                value: 'ダウンロード',
                onClick: () => {
                    const a = document.createElement('a');
                    a.href =
                        'data:application/json,' +
                        encodeURIComponent(
                            JSON.stringify({ client_id: appId, client_secret: state.response.client_secret })
                        );
                    a.download = state.input.name + '.json';
                    a.click();
                },
            })
        ),
    ]);
    return React.createElement('div', { className: 'container' }, EditMenu);
};
