import React from 'react';
import image from '../resources/sorry.png';
import '../css/404.css';

export const NotFoundItem = () => {
    const [keyState, setKeyState] = React.useState(false);
    React.useEffect(() => {
        const KeyDownEvent = e => {
            const keyCode = e.keyCode;
            if (keyCode === 16 || keyCode === 44 || keyCode === 91 || keyCode === 92) {
                setKeyState(true);
            }
        };
        const KeyUpEvent = () => {
            setKeyState(true);
        };
        document.addEventListener('keydown', KeyDownEvent);
        document.addEventListener('keyup', KeyUpEvent);
    });
    return React.createElement(
        'div',
        { className: 'container', style: { textAlign: 'center', style: { display: keyState ? 'none' : 'block' } } },
        [
            React.createElement('img', {
                className: 'notfound',
                src: image,
                onselectstart: 'return false;',
                onmousedown: 'return false;',
            }),
            React.createElement('h4', null, 'お探しのページは見つかりませんでした'),
        ]
    );
};
