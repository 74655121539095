import React from 'react';
import toastr from 'toastr';
import contactConfig from '../resources/contact.json';
import '../css/contact.css';
const MailAddressReg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
export const ContactItem = () => {
    const [state, setState] = React.useState({
        name: '',
        response_to: '',
        subject: contactConfig.contact_type[0],
        message: '',
    });
    const NameInputForm = React.createElement('input', {
        type: 'text',
        placeholder: '名前（ペンネーム可）を入力して下さい',
        onBlur: e => {
            const Current = Object.assign({}, state);
            Current.name = e.target.value;
            setState(Current);
        },
    });
    const MailAddressInputForm = React.createElement('input', {
        type: 'email',
        placeholder: '入力例：xxx@example.com',
        onInput: e => {
            const Current = Object.assign({}, state);
            Current.response_to = e.target.value;
            setState(Current);
        },
    });
    const ContactTypeSelectForm = React.createElement(
        'select',
        {
            defaultValue: contactConfig.contact_type[0],
            onChange: e => {
                const Current = Object.assign({}, state);
                Current.subject = e.target.value;
                setState(Current);
            },
        },
        contactConfig.contact_type.map(i => React.createElement('option', { value: i }, i))
    );
    const DetailInputForm = React.createElement('textarea', {
        className: 'contact_detail',
        placeholder: 'お問い合わせの詳細をここに入力してください',
        onChange: e => {
            const Current = Object.assign({}, state);
            Current.message = e.target.value;
            setState(Current);
        },
    });
    return React.createElement('div', { className: 'container' }, [
        React.createElement('h3', null, 'お問い合わせ'),
        React.createElement('dl', null, [
            React.createElement('dt', null, React.createElement('label', null, 'お名前')),
            React.createElement('dd', null, NameInputForm),
            React.createElement('dt', null, React.createElement('label', null, '連絡先メールアドレス')),
            React.createElement('dd', null, MailAddressInputForm),
            React.createElement(
                'dd',
                null,
                React.createElement(
                    'p',
                    null,
                    '※メールアドレスに誤りがある場合、お問い合わせの回答ができない場合があります。'
                )
            ),
            React.createElement('dt', null, React.createElement('label', null, 'お問い合わせ内容')),
            React.createElement('dd', null, ContactTypeSelectForm),
            React.createElement('dt', null, React.createElement('label', null, 'お問い合わせ内容（詳細）')),
            React.createElement('dd', null, DetailInputForm),
            React.createElement(
                'dd',
                null,
                React.createElement(
                    'p',
                    null,
                    React.createElement(
                        'p',
                        null,
                        '※Meigetsu IDをお持ちの方は、IDをご記入いただくとスムーズに確認できることがございます。'
                    )
                )
            ),
        ]),
        React.createElement('input', {
            type: 'submit',
            value: '送信',
            onClick: () => {
                if (
                    Object.keys(state)
                        .map(i => state[i].length)
                        .reduce((acc, cur) => acc * cur, 1) === 0
                ) {
                    toastr['error']('全ての項目を入力して下さい。');
                    return;
                }
                if (!MailAddressReg.test(state.response_to)) {
                    toastr['error']('メールアドレスに問題があります');
                    return;
                }
                fetch('https://idportal.meigetsu.jp/app/contact', {
                    method: 'POST',
                    credentials: 'omit',
                    body: JSON.stringify(state),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => response.text())
                    .then(id => {
                        location.href = `/contact/${id}`;
                    })
                    .catch(er => toastr['error'](er.message));
            },
        }),
    ]);
};
