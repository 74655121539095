import React from 'react';
import { TokenManager } from '../../process/TokenManager.js';
import accountinfo from '../../resources/accountinfos.json';

/**
 *
 * @param {Date} Dt
 * @returns {string}
 */
const CreateDateTimeText = Dt =>
    `${Dt.getFullYear()}/${Dt.getMonth() + 1}/${Dt.getDate()} ${Dt.getHours()}:${Dt.getMinutes()}:${Dt.getSeconds()}`;

export const UserProfileItem = () => {
    const [state, setState] = React.useState({
        done: false,
        record: {
            user_id: '',
            mailaddress: '',
            name: '',
            account_type: '',
            created_at: new Date(),
            personality_classification: 0,
            personal: {
                first_name: '',
                family_name: '',
                prefecture: '',
                city: '',
                address: '',
                birthday: new Date(),
                gender: '',
                user_check_status: '',
            },
        },
    });
    React.useEffect(() => {
        if (state.done) return;
        const AfterInspection = async () => {
            const Token = TokenManager.get();
            if (Token.accessToken == null) {
                location.href = '/ready';
                return Promise.resolve();
            }
            await fetch('https://idportal.meigetsu.jp/api/v2/user?contain_personal=true', {
                method: 'GET',
                credentials: 'omit',
                headers: { Authorization: `Bearer ${Token.accessToken}` },
            })
                .then(record => record.json())
                .then(json => {
                    const AccountRecord = {
                        user_id: json.user_id,
                        mailaddress: json.mailaddress,
                        name: json.name,
                        account_type: accountinfo.account_types[json.account_type],
                        created_at: new Date(json.created_at),
                        personality_classification: json.personality_classification,
                        personal:
                            json.personal == null
                                ? {
                                      first_name: '未登録',
                                      family_name: '未登録',
                                      prefecture: '未登録',
                                      city: '未登録',
                                      address: '未登録',
                                      birthday: null,
                                      gender: '未登録',
                                      user_check_status: accountinfo.check_levels[0],
                                  }
                                : {
                                      first_name: json.personal.first_name ?? '未登録',
                                      family_name: json.personal.family_name ?? '未登録',
                                      prefecture: json.personal.prefecture ?? '未登録',
                                      city: json.personal.city ?? '未登録',
                                      address: json.personal.address ?? '未登録',
                                      birthday:
                                          json.personal.birthday == null ? null : new Date(json.personal.birthday),
                                      gender:
                                          json.personal.gender == null
                                              ? '未登録'
                                              : accountinfo.genders[json.personal.gender],
                                      user_check_status:
                                          json.personal.check_level == null
                                              ? accountinfo.check_levels[0]
                                              : accountinfo.check_levels[json.personal.check_level],
                                  },
                    };
                    setState({ done: true, record: AccountRecord });
                });
        };
        TokenManager.inspection().then(() => AfterInspection());
    });
    if (!state.done)
        return React.createElement(
            'div',
            { className: 'container' },
            React.createElement('p', null, 'アカウント情報取得中...')
        );
    const concatenate = (...values) => {
        let val = '';
        values.forEach(i => {
            if (i !== null) val += i;
        });
        return val.length === 0 ? '未登録' : val;
    };
    const BasicRecord = React.createElement('dl', null, [
        React.createElement('dt', null, 'ユーザーＩＤ'),
        React.createElement('dd', null, state.record.user_id),
        React.createElement('dt', null, 'ユーザー名'),
        React.createElement('dd', null, state.record.name),
        React.createElement('dt', null, 'メールアドレス'),
        React.createElement('dd', null, state.record.mailaddress),
        React.createElement('dt', null, '人格区分'),
        React.createElement(
            'dd',
            null,
            accountinfo.personality_classification[state.record.personality_classification]
        ),
        React.createElement('dt', null, 'アカウント種別'),
        React.createElement('dd', null, state.record.account_type),
        React.createElement('dt', null, 'アカウント作成日'),
        React.createElement('dd', null, CreateDateTimeText(state.record.created_at)),
    ]);
    const getAddress = () => {
        const data = concatenate(
            state.record.personal.prefecture,
            state.record.personal.city,
            state.record.personal.address
        ).replaceAll('未登録', '');
        return data.length === 0 ? '未登録' : data;
    };
    const PersonalRecord = React.createElement('dl', null, [
        React.createElement('dt', null, '名字'),
        React.createElement('dd', null, state.record.personal.family_name),
        React.createElement('dt', null, '名前'),
        React.createElement('dd', null, state.record.personal.first_name),
        React.createElement('dt', null, '住所'),
        React.createElement('dd', null, getAddress()),
        React.createElement('dt', null, '誕生日'),
        React.createElement(
            'dd',
            null,
            state.record.personal.birthday == null
                ? '未登録'
                : `${state.record.personal.birthday.getFullYear()}/${
                      state.record.personal.birthday.getMonth() + 1
                  }/${state.record.personal.birthday.getDate()}`
        ),
        React.createElement('dt', null, '本人確認等ステータス'),
        React.createElement('dd', null, state.record.personal.user_check_status),
    ]);
    const UpdateButton = React.createElement('input', {
        type: 'button',
        value: '登録情報を更新する',
        onClick: () => {
            location.href = '/edit';
        },
    });
    const ForPersonal = React.createElement('div', { className: 'container' }, [
        React.createElement('section', null, [
            React.createElement('div', { className: 'box_title' }, '基本情報'),
            React.createElement('div', { className: 'box_content' }, BasicRecord),
        ]),
        React.createElement('section', null, [
            React.createElement('div', { className: 'box_title' }, '個人情報'),
            React.createElement('div', { className: 'box_content' }, PersonalRecord),
        ]),
        UpdateButton,
    ]);
    const ForCorp = React.createElement('div', { className: 'container' }, [
        React.createElement('section', null, [
            React.createElement('div', { className: 'box_title' }, '基本情報'),
            React.createElement('div', { className: 'box_content' }, BasicRecord),
        ]),
        UpdateButton,
    ]);
    return state.record.personality_classification === 0 ? ForPersonal : ForCorp;
};
