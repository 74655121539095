import React from 'react';
import paths from '../resources/paths.json';
import '../css/header.css';
import { TokenManager } from '../process/TokenManager';

const HeaderNotViewPage = ['/oauth', '/ready', '/callback'];

const PCHeaderItem = () => {
    if (HeaderNotViewPage.some(i => location.pathname === i)) return <nav></nav>;
    const Token = TokenManager.get();
    const NaviItems = paths.paths.map(p => {
        return React.createElement(
            'li',
            { title: p.text, key: p.class },
            React.createElement(
                'a',
                { className: location.pathname === p.href ? 'active ' + p.class : p.class, href: p.href },
                p.text
            )
        );
    });
    if (Token.accessToken == null || location.pathname === '/signout') {
        NaviItems.push(
            React.createElement(
                'li',
                { title: 'サインイン', key: 'signin' },
                React.createElement('a', { className: 'signin', href: '/ready' }, 'サインイン')
            ),
            React.createElement(
                'li',
                { title: 'サインアップ', key: 'signup' },
                React.createElement(
                    'a',
                    { className: location.pathname === '/signup' ? 'active signup' : 'signup', href: '/signup' },
                    'サインアップ'
                )
            )
        );
    } else {
        NaviItems.push(
            React.createElement(
                'li',
                { title: 'サインアウト', key: 'signout' },
                React.createElement('a', { className: 'signout', href: '/signout' }, 'サインイン')
            )
        );
    }
    const naviItem = React.createElement('ul', { className: 'menu' }, NaviItems);
    return React.createElement('nav', null, naviItem);
};

const SPHeaderItem = () => {
    if (HeaderNotViewPage.some(i => location.pathname === i)) return <nav></nav>;
    const [state, setState] = React.useState({ signin: false, aria_expanded: false });
    React.useEffect(() => {
        TokenManager.inspection().then(() => {
            const TokenData = TokenManager.get();
            const Current = Object.assign({}, state);
            Current.signin = TokenData.accessToken !== null;
            setState(Current);
        });
    });
    const NoSignIn = [
        React.createElement(
            'li',
            { className: 'nav-item' },
            React.createElement('a', { className: 'nav-link text-dark', href: '/ready' }, 'サインイン')
        ),
        React.createElement(
            'li',
            { className: 'nav-item' },
            React.createElement('a', { className: 'nav-link text-dark', href: '/signup' }, 'サインアップ')
        ),
    ];
    const AlreadySignIn = [
        React.createElement(
            'li',
            { className: 'nav-item' },
            React.createElement('a', { className: 'nav-link text-dark', href: '/' }, 'トップ')
        ),
        React.createElement(
            'li',
            { className: 'nav-item' },
            React.createElement('a', { className: 'nav-link text-dark', href: '/user' }, 'プロフィール')
        ),
        React.createElement(
            'li',
            { className: 'nav-item' },
            React.createElement('a', { className: 'nav-link text-dark', href: '/myapp' }, 'マイアプリ')
        ),
        React.createElement(
            'li',
            { className: 'nav-item' },
            React.createElement('a', { className: 'nav-link text-dark', href: '/signout' }, 'サインアウト')
        ),
    ];
    return React.createElement(
        'nav',
        {
            className:
                'navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3',
        },
        [
            React.createElement(
                'div',
                {
                    className: 'navbar-collapse d-sm-inline-flex flex-sm-row-reverse collapse show',
                },
                React.createElement(
                    'ul',
                    { className: 'navbar-nav flex-grow-1' },
                    state.signin ? AlreadySignIn : NoSignIn
                )
            ),
        ]
    );
};

export const HeaderItem = () => {
    return navigator.userAgent.match(/iPhone|Android.+Mobile/) ? SPHeaderItem() : PCHeaderItem();
};
