import React from 'react';
import toastr from 'toastr';
import { TokenManager } from '../../process/TokenManager.js';

export const AppCreateItem = () => {
    const [state, setState] = React.useState({
        done: false,
        input: {
            name: '',
            description: '',
            callback_url: '',
            privacy: '',
            term: '',
            public: true,
        },
        response: {
            client_id: '',
            client_secret: '',
        },
    });
    React.useEffect(() => {
        if (state.done) return;
        TokenManager.inspection().then(() => {
            const Token = TokenManager.get();
            if (Token.accessToken == null) {
                location.href = '/ready';
                return Promise.resolve();
            }
            const CurrentState = Object.assign({}, state);
            CurrentState.done = true;
            setState(CurrentState);
        });
    });
    const EditMenu = React.createElement('dl', null, [
        React.createElement('dt', null, 'アプリケーションの名前'),
        React.createElement(
            'dd',
            null,
            React.createElement('input', {
                type: 'text',
                value: state.input.name,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.name = e.target.value;
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, 'アプリケーションの説明'),
        React.createElement(
            'dd',
            null,
            React.createElement('textarea', {
                value: state.input.description,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.description = e.target.value.replaceAll(/\r\n|\n|\r/g, '\n');
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, 'コールバックURL'),
        React.createElement(
            'dd',
            null,
            React.createElement('input', {
                type: 'text',
                value: state.input.callback_url,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.callback_url = e.target.value;
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, 'プライバシーポリシー'),
        React.createElement(
            'dd',
            null,
            React.createElement('input', {
                type: 'text',
                value: state.input.privacy,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.privacy = e.target.value;
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, '利用規約'),
        React.createElement(
            'dd',
            null,
            React.createElement('input', {
                type: 'text',
                value: state.input.term,
                onChange: e => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.term = e.target.value;
                    setState(CurrentState);
                },
            })
        ),
        React.createElement('dt', null, 'アプリケーションの種類'),
        React.createElement('dd', null, [
            React.createElement('input', {
                type: 'checkbox',
                checked: state.input.public,
                onChange: e => {
                    toastr.info(
                        e.target.checked
                            ? 'アプリケーションの種類をPublicにします'
                            : 'アプリケーションの種類をConfidentialにします'
                    );
                    const CurrentState = Object.assign({}, state);
                    CurrentState.input.public = e.target.checked;
                    setState(CurrentState);
                },
            }),
            React.createElement('label', null, 'public'),
        ]),
    ]);
    return React.createElement('div', { className: 'container' }, [
        EditMenu,
        React.createElement('input', {
            type: 'submit',
            value: '作成',
            onClick: () => {
                const Data = Object.assign({}, state.input);
                Object.keys(Data).forEach(key => {
                    if (Data[key].length === 0) delete Data[key];
                });
                fetch('https://idportal.meigetsu.jp/api/v2/application', {
                    method: 'POST',
                    body: JSON.stringify(Data),
                    headers: {
                        Authorization: 'Bearer ' + TokenManager.get().accessToken,
                        'Content-Type': 'application/json',
                    },
                }).then(response => {
                    if (response.status !== 201) return response.text().then(text => toastr.error(text));
                    toastr.success('アプリケーションを作成しました。');
                    return response.json().then(json => {
                        const a = document.createElement('a');
                        a.href = 'data:application/json,' + encodeURIComponent(JSON.stringify(json));
                        a.download = state.input.name + '.json';
                        a.click();
                        const CurrentState = Object.assign({}, state);
                        CurrentState.response.client_id = json.client_id;
                        CurrentState.response.client_secret = json.client_secret;
                        setState(CurrentState);
                    });
                });
            },
        }),
        React.createElement('dl', null, [
            React.createElement(
                'dt',
                { style: { visibility: state.response.client_id.length > 0 ? 'visible' : 'hidden' } },
                'クライアントＩＤ'
            ),
            React.createElement(
                'dd',
                { style: { visibility: state.response.client_id.length > 0 ? 'visible' : 'hidden' } },
                React.createElement('input', { type: 'text', value: state.response.client_id, readOnly: true })
            ),
            React.createElement(
                'dt',
                { style: { visibility: state.response.client_secret.length > 0 ? 'visible' : 'hidden' } },
                'クライアントシークレット'
            ),
            React.createElement(
                'dd',
                { style: { visibility: state.response.client_secret.length > 0 ? 'visible' : 'hidden' } },
                React.createElement('input', { type: 'text', value: state.response.client_secret, readOnly: true })
            ),
        ]),
    ]);
};
