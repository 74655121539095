import React from 'react';
import { useParams } from 'react-router-dom';

export const AfterContactItem = () => {
    const { contactId } = useParams();
    if (contactId == null) {
        location.href = '/signup';
        return <p></p>;
    }
    return (
        <div className="container">
            <h3>お問い合わせ完了</h3>
            <p>お問い合わせ番号：{contactId}</p>
            <p>お問い合わせありがとうございます。</p>
            <p>お問い合わせ内容を確認後、ご連絡を差し上げますのでしばらくお待ち下さい。</p>
        </div>
    );
};
