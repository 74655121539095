import React from 'react';
import { TokenManager } from '../../process/TokenManager.js';
import '../../css/appview.css';

export const ApplicationListViewItem = () => {
    const [state, setState] = React.useState({ done: false, apps: [] });
    React.useEffect(() => {
        if (state.done) return;
        TokenManager.inspection().then(() => {
            const Token = TokenManager.get();
            if (Token.accessToken == null) {
                location.href = '/ready';
                return Promise.resolve();
            }
            return fetch('https://idportal.meigetsu.jp/api/v2/application', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${Token.accessToken}`,
                },
            })
                .then(response => {
                    if (response.status !== 200) return response.text().then(text => Promise.reject(new Error(text)));
                    return response.json();
                })
                .then(json => {
                    setState({ done: true, apps: json.applications });
                })
                .catch(er => console.error(er));
        });
    });
    const CreateNewApplication = React.createElement('input', {
        type: 'button',
        value: '新規作成',
        onClick: () => (location.href = '/application/new'),
    });
    if (state.apps.length === 0)
        return (
            <div className="container">
                <p>アプリケーションがありません</p>
                {CreateNewApplication}
            </div>
        );
    return React.createElement(
        'div',
        {
            className: 'container',
        },
        [
            CreateNewApplication,
            ...state.apps.map(item => {
                const Article = React.createElement('article', { className: 'application', key: item.client_id }, [
                    React.createElement('h5', null, item.name),
                    ...item.description.split('\n').map(i => React.createElement('p', null, i)),
                ]);
                return React.createElement(
                    'a',
                    { className: 'application_card', href: `/application/${item.client_id}` },
                    Article
                );
            }),
            React.createElement('p', null, [
                'Meigetsu IDの仕様書は',
                React.createElement('a', { href: '/api/spec' }, 'こちら'),
            ]),
        ]
    );
};
