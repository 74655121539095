import React from 'react';
import '../css/table.css';

export const TermItem = () => {
    return (
        <div className="container">
            <h2>利用規約</h2>
            <p>
                この利用規約（以下、本規約）は、明月（以下、当社）がMeigetsu
                ID（以下、本サービス）を提供するにあたり、これの利用条件を定めるものです。
            </p>
            <p>本サービスを利用されるユーザーの皆様には、本規約に従って、本サービスをご利用いただきます。</p>
            <section>
                <h3>第１条 適用</h3>
                <ol>
                    <li>
                        <p>本規約は、ユーザーと当社との本サービスの利用に関わる一切の関係に適用されるものとします。</p>
                    </li>
                    <li>
                        <p>
                            当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、個別規定）をすることがあります。これらの個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
                        </p>
                    </li>
                    <li>
                        <p>
                            本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定が優先されるものとします。
                        </p>
                    </li>
                </ol>
            </section>
            <section>
                <h3>第２条 利用登録</h3>
                <ol>
                    <li>
                        <p>
                            本サービスにおいては、本規約および
                            <a href="https://www.meigetsu.jp/privacy.html">当社プライバシーポリシー</a>
                            に同意の上、アカウント登録をすることにより、利用登録が完了するものとします。
                        </p>
                    </li>
                    <li>
                        <p>
                            当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
                        </p>
                        <ol>
                            <li>
                                <p>本規約に違反したことがある者からの申請である場合</p>
                            </li>
                            <li>
                                <p>その他、当社が利用登録を相当でないと判断した場合</p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </section>
            <section>
                <h3>第３条 IDおよびパスワードの管理</h3>
                <ol>
                    <li>
                        <p>
                            ユーザーは、自己の責任において、本サービスのIDおよびパスワードを適切に管理するものとします。
                        </p>
                    </li>
                    <li>
                        <p>
                            ユーザーは、いかなる場合にも、IDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。
                        </p>
                    </li>
                    <li>
                        <p>
                            当社は、IDとパスワードの組み合わせが登録情報と一致してサインインされた場合には、そのIDを登録しているユーザー自身による利用とみなします。
                        </p>
                    </li>
                    <li>
                        <p>
                            IDおよびパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。
                        </p>
                    </li>
                </ol>
            </section>
            <section>
                <h3>第４条 利用登録および解除</h3>
                <ol>
                    <li>
                        本サービスにおいては、本規約および
                        <a href="https://www.meigetsu.jp/privacy.html">当社プライバシーポリシー</a>
                        に同意の上、ポータルサイトに定められた方法でアカウントを登録することにより、利用登録が完了するものとします。
                    </li>
                    <li>
                        アカウントの削除についてはポータルサイトに定められた方法でアカウントの削除操作を行うことにより実施するものとし、当該手続きをもって利用登録が解除されるものとします。
                    </li>
                    <li>
                        当社は、ユーザーが本規約に違反した場合等、本サービスの利用が適当でないと判断した場合にはいつでも利用登録を解除できるものとします。
                    </li>
                </ol>
            </section>
            <section>
                <h3>第５条 個人情報の登録および削除</h3>
                <ol>
                    <li>
                        <p>個人情報の登録については、ユーザーの判断に委任するものとします。</p>
                    </li>
                    <li>
                        <p>
                            個人情報を登録する際には住民票等に記載の正確な情報を登録するものとし、芸名や事務所の住所等の登録はできないものとします。
                        </p>
                    </li>
                    <li>
                        <p>
                            住所を登録する際には、簡便な形式で入力することができるものとします。ただし、都道府県、市区町村、町名の省略はできません。
                        </p>
                        <p>OKな例：東京都新宿区西新宿２－８－１</p>
                        <p>NGな例：新宿区西新宿２－８－１</p>
                    </li>
                    <li>
                        <p>
                            ユーザーは、以下の事由に該当する場合、当社の定める方法により、氏名、性別、住所、生年月日を届け出なければなりません。
                        </p>
                        <ol>
                            <li>
                                <p>18歳未満の者が利用できないサービスを利用する場合</p>
                            </li>
                            <li>
                                <p>ムーンクリスタルの購入等、本サービスを使用して有償サービスを利用する場合</p>
                            </li>
                            <li>
                                <p>その他、法令に定められた事由が発生した場合</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            登録された個人情報は、本サービスの登録の解除をもって削除するものとします。ただし、下記の場合については、当社の個人情報総合管理システムによって管理するものとします。
                        </p>
                        <table className="mgdesc">
                            <thead>
                                <tr>
                                    <th>条件</th>
                                    <th>管理する期間</th>
                                    <th>起算日</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>本サービスを使用して有償サービスを利用した場合</td>
                                    <td>５年間</td>
                                    <td>
                                        本サービスの登録を解除した日または料金の未納の状態が解消した日のいずれか遅い日の翌年４月１日
                                    </td>
                                </tr>
                                <tr>
                                    <td>法令等に基づく要請があった場合</td>
                                    <td>当該要請に定められた期間の経過または期日の到来まで</td>
                                    <td>本サービスの登録を解除した日の翌年４月１日</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                </ol>
            </section>
            <section>
                <h3>第６条 制限行為能力者による本サービスの利用</h3>
                <ol>
                    <li>
                        <p>
                            民法の定める制限行為能力者（以下、制限行為能力者）が本サービスを利用するにあたっては、原則として30日以内に法定代理人の届出をしなければなりません。
                        </p>
                    </li>
                    <li>
                        <p>
                            制限行為能力者が本サービスを利用するにあたっては、法定代理人が利用に関して責任を負うものとします。
                        </p>
                    </li>
                    <li>
                        <p>
                            当社は、登録から30日以内に法定代理人の届出が無い場合、原則として行為能力者（制限行為能力者でない者）による登録であるとみなします。
                        </p>
                    </li>
                    <li>
                        <p>
                            当社は、法定代理人が正当な理由なく30日以内に届出を行わない場合、制限行為能力者のアカウントの利用を制限できるものとします。
                        </p>
                    </li>
                </ol>
            </section>
            <section>
                <h3>第７条 禁止事項</h3>
                <p>ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。</p>
                <ol>
                    <li>
                        <p>法令または公序良俗に違反する行為</p>
                    </li>
                    <li>
                        <p>犯罪行為に関連する行為</p>
                    </li>
                    <li>
                        <p>本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為</p>
                    </li>
                    <li>
                        <p>
                            当社、他のユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
                        </p>
                    </li>
                    <li>
                        <p>本サービスによって得られた情報を商業的に利用する行為</p>
                    </li>
                    <li>
                        <p>当社のサービスの運営を妨害、または妨害するおそれのある行為</p>
                    </li>
                    <li>
                        <p>不正アクセスをし、これを試みる行為</p>
                    </li>
                    <li>
                        <p>個人情報の登録にあたり、虚偽の情報または他者の情報を登録する行為</p>
                    </li>
                    <li>
                        <p>正当な理由なく他のユーザーに関する情報等を収集または蓄積する行為</p>
                    </li>
                    <li>
                        <p>不正な目的をもって本サービスを利用する行為</p>
                    </li>
                    <li>
                        <p>本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</p>
                    </li>
                    <li>
                        <p>他のユーザーに成りすます行為</p>
                    </li>
                    <li>
                        <p>行為能力者が制限行為能力者であることを装う行為</p>
                    </li>
                    <li>
                        <p>制限行為能力者が行為能力者であることを装う行為</p>
                    </li>
                    <li>
                        <p>当社が許諾しない本サービス上での宣伝、広告、勧誘または営業行為</p>
                    </li>
                    <li>
                        <p>
                            本サービス、またはマッチングサイト等の本サービスと連携する他者との出会いを目的とする者が集まる場を提供するサービス以外での出会いを目的とした行為
                        </p>
                    </li>
                    <li>
                        <p>
                            当社のサービスに関連して、反社会的勢力、その他犯罪を目的とした集団等に対して直接または間接的に利益を供与する行為
                        </p>
                    </li>
                    <li>
                        <p>その他、当社が不適切と判断する行為</p>
                    </li>
                </ol>
            </section>
            <section>
                <h3>第８条 本サービスの提供の停止等</h3>
                <ol>
                    <li>
                        <p>
                            当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                        </p>
                        <ol>
                            <li>
                                <p>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</p>
                            </li>
                            <li>
                                <p>
                                    地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
                                </p>
                            </li>
                            <li>
                                <p>コンピュータまたは通信回線等が事故により停止した場合</p>
                            </li>
                            <li>
                                <p>その他、当社が本サービスの提供が困難と判断した場合</p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
                        </p>
                    </li>
                </ol>
            </section>
            <section>
                <h3>第９条 保証の否認および免責事項</h3>
                <ol>
                    <li>
                        <p>
                            当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
                        </p>
                    </li>
                    <li>
                        <p>
                            当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
                        </p>
                    </li>
                    <li>
                        <p>
                            前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
                        </p>
                    </li>
                    <li>
                        <p>
                            当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
                        </p>
                    </li>
                </ol>
            </section>
            <section>
                <h3>第１０条 サービス内容の変更等</h3>
                <p>
                    当社は、ユーザーに通知することなく、本サービスの内容を変更し、または本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
                </p>
            </section>
            <section>
                <h3>第１１条 利用規約の変更</h3>
                <p>
                    当社は、必要と判断した場合にはユーザーに通知することなくいつでも本規約を変更することができるものとします。なお、本規約の変更後、本サービスの利用を開始した場合には、当該ユーザーは変更後の規約に同意したものとみなします。
                </p>
            </section>
            <section>
                <h3>第１２条 通知または連絡</h3>
                <ol>
                    <li>
                        <p>ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。</p>
                    </li>
                    <li>
                        <p>
                            当社は、ユーザーから当社が別途定める方式に従った変更の届出が無い限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは発信時にユーザーへ到達したものとみなします。
                        </p>
                    </li>
                </ol>
            </section>
            <section>
                <h3>第１３条 権利義務の譲渡の禁止</h3>
                <p>
                    ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
                </p>
            </section>
            <section>
                <h3>第１４条 準拠法</h3>
                <p>本規約の解釈にあたっては、日本法を準拠法とします。</p>
            </section>
            <section>
                <h3>第１５条 合意管轄に関する事項</h3>
                <p>
                    本サービスに関する一切の紛争（調停による裁判手続きを含む）は、東京地方裁判所を第一審の専属的合意管轄裁判所とすることに合意するものとします。
                </p>
            </section>
        </div>
    );
};
