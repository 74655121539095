import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export const SpecItem = () => {
    return (
        <div className="container">
            <p>
                本APIの利用にあたっては、<a href="/api/term">API利用規約</a>に同意する必要があります。
            </p>
            <SwaggerUI url="/api/v2/spec" />
            <p>
                ※Meigetsu
                IDは現在調整中であり、仕様が変わることがあります。また、このドキュメントは不完全なため、一部のデータが抜けている場合がございます。
            </p>
            <p>
                Meigetsu IDの認可ＵＲＬを生成したい方は<a href="/api/urlgen">こちら</a>で生成して下さい。
            </p>
        </div>
    );
};
