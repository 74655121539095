import React from 'react';
import { TokenManager } from '../../process/TokenManager.js';
import toastr from 'toastr';
const ConfirmCodePattern = /^[0-9]{6}$/;

export const UpdateConfirmItem = () => {
    const [state, setState] = React.useState('');
    return React.createElement('div', { className: 'container' }, [
        React.createElement('section', null, [
            React.createElement('p', null, 'ご登録いただいたメールアドレス宛にメッセージを送信します。'),
            React.createElement('p', null, 'メッセージ上のＵＲＬから更新のお手続きを行って下さい。'),
            React.createElement('p', null, 'メールを送信する場合は、下のボタンを押して下さい。'),
            React.createElement('input', {
                type: 'button',
                value: 'メール送信',
                onClick: async () => {
                    return await TokenManager.inspection()
                        .then(() => {
                            const Token = TokenManager.get();
                            if (Token.accessToken == null) {
                                location.href = '/ready';
                                return Promise.resolve();
                            }
                            return fetch('https://idportal.meigetsu.jp/api/v2/user', {
                                method: 'PATCH',
                                credentials: 'omit',
                                headers: {
                                    Authorization: `Bearer ${Token.accessToken}`,
                                },
                            }).then(response => {
                                if (response.status === 200) {
                                    toastr['success']('メールを送信しました');
                                    return Promise.resolve();
                                } else return response.text().then(txt => Promise.reject(new Error(txt)));
                            });
                        })
                        .catch(er => toastr['error'](er.message));
                },
            }),
        ]),
        React.createElement('section', null, [
            React.createElement(
                'label',
                {
                    htmlFor: 'confirm_code',
                },
                '確認コード'
            ),
            React.createElement('input', {
                id: 'confirm_code',
                type: 'text',
                value: state,
                onChange: e => setState(e.target.value),
            }),
            React.createElement('input', {
                type: 'button',
                value: '更新開始',
                onClick: async () => {
                    if (!ConfirmCodePattern.test(state)) {
                        toastr['error']('確認コードは6桁の数字で入力してください');
                        return;
                    }
                    return await TokenManager.inspection()
                        .then(() => {
                            const Token = TokenManager.get();
                            return fetch('https://idportal.meigetsu.jp/api/v2/user/' + state, {
                                headers: {
                                    Authorization: `Bearer ${Token.accessToken}`,
                                },
                            }).then(response => {
                                if (response.status === 200) {
                                    location.href = '/edit/' + state;
                                    return Promise.resolve();
                                } else return response.text().then(txt => Promise.reject(new Error(txt)));
                            });
                        })
                        .catch(er => toastr['error'](er.message));
                },
            }),
        ]),
    ]);
};
