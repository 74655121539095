import React from 'react';

export const OAuthURLGeneratorItem = () => {
    const [state, setState] = React.useState({
        input: {
            checked_scopes: [],
            client_id: '',
            client_secret: '',
            callback: '',
            code_challenge: '',
            code_challenge_method: '',
            generated_url: '',
        },
        scopes: [],
    });
    React.useEffect(() => {
        if (state.scopes.length > 0) return;
        fetch('/app/api')
            .then(response => response.json())
            .then(json => {
                const scopeTable = json.components.securitySchemes.oauth2.flows.authorizationCode.scopes;
                const Current = Object.assign({}, state);
                Current.scopes = Object.keys(scopeTable).map(i => ({ name: i, description: scopeTable[i] }));
                setState(Current);
            });
    });
    /**
     *
     * @param {{ name: string, description: string }} data
     * @returns {React.JSX.Element}
     */
    const CheckBoxSection = data => {
        return React.createElement('section', null, [
            React.createElement('input', {
                type: 'checkbox',
                value: false,
                id: data.name.replace('.', '_'),
                onChange: e => {
                    if (e.target.value) {
                        if (!state.input.checked_scopes.includes(data.name)) {
                            const Current = Object.assign({}, state);
                            Current.input.checked_scopes.push(data.name);
                            setState(Current);
                        }
                    } else {
                        const Current = Object.assign({}, state);
                        Current.input.checked_scopes = Current.input.checked_scopes.filter(i => i !== data.name);
                        setState(Current);
                    }
                },
            }),
            React.createElement(
                'label',
                {
                    htmlFor: data.name.replace('.', '_'),
                },
                data.name
            ),
            React.createElement('p', null, data.description),
        ]);
    };
    /**
     *
     * @param {string} labelText
     * @param {string} stateKey
     * @returns {React.JSX.Element[]}
     */
    const TextInputForm = (labelText, stateKey, readonly = false) => {
        return [
            React.createElement('dt', null, React.createElement('label', null, labelText)),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'text',
                    readOnly: readonly,
                    value: state.input[stateKey],
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.input[stateKey] = e.target.value;
                        setState(Current);
                    },
                })
            ),
        ];
    };
    const GenButton = React.createElement('input', {
        type: 'button',
        value: '生成',
        onClick: () => {
            const data = {
                response_type: 'code',
                client_id: state.input.client_id,
                redirect_uri: encodeURI(state.input.callback),
                scope: state.input.checked_scopes.join('+'),
                code_challenge: state.input.code_challenge,
                code_challenge_method: state.input.code_challenge_method,
            };
            if (state.input.client_secret.length > 0) data['client_secret'] = state.input.client_secret;
            const QueryParameters = new URLSearchParams(data);
            const Current = Object.assign({}, state);
            Current.input.generated_url = `https://idportal.meigetsu.jp/oauth2/auth?${QueryParameters.toString()}`;
            setState(Current);
        },
    });
    return React.createElement('div', { className: 'container' }, [
        React.createElement('h3', null, 'Meigetsu ID OAuth URL Generator'),
        React.createElement('dl', null, [
            ...TextInputForm('Client ID', 'client_id'),
            ...TextInputForm('Client Secret', 'client_secret'),
            ...TextInputForm('Redirect URL', 'callback'),
            ...TextInputForm('Code Challenge Text', 'code_challenge'),
            ...TextInputForm('Code Challange Method', 'code_challenge_method'),
        ]),
        React.createElement('section', null, [
            React.createElement('label', null, 'Scopes'),
            ...state.scopes.map(i => CheckBoxSection(i)),
        ]),
        GenButton,
        TextInputForm('Generated URL', 'generated_url'),
    ]);
};
