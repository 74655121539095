import React from 'react';
import '../css/box.css';

export const NotificationItem = () => {
    return (
        <div className="container">
            <p>届出の種類に応じてタイトルの部分を押して下さい。</p>
            <p>※メールソフトが起動します。</p>
            <section>
                <div className="box_title">
                    <a href="mailto:info@mail.meigetsu.jp?subject=制限行為能力者のMeigetsu ID登録に伴う法定代理人の届出">
                        制限行為能力者のMeigetsu ID登録に伴う法定代理人の届出
                    </a>
                </div>
                <div className="box_content">
                    <p>
                        届出をお願いする事項は下記の通りです。制限行為能力者の権利保護のため、ご協力をお願い致します。
                    </p>
                    <ul>
                        <li>
                            <p>制限行為能力者のMeigetsu IDのユーザーID</p>
                        </li>
                        <li>
                            <p>登録に使用したメールアドレス</p>
                        </li>
                        <li>
                            <p>法定代理人の氏名</p>
                        </li>
                        <li>
                            <p>法定代理人の連絡先（電話番号またはメールアドレス）</p>
                        </li>
                    </ul>
                    <p>法定代理人の氏名、連絡先につきましては複数届け出ていただいても差し支えありません。</p>
                    <p>なお、当社からご連絡を差し上げる場合の連絡先につきましては下記の通りです。</p>
                    <ul>
                        <li>電話番号：03-6161-6038</li>
                        <li>メール：@mail.meigetsu.jpで終わるメールアドレス</li>
                    </ul>
                </div>
            </section>
            <section>
                <div className="box_title">
                    <a href="mailto:info@mail.meigetsu.jp?subject=本人確認・年齢確認のお願い">本人確認・年齢確認</a>
                </div>
                <div className="box_content">
                    <p>
                        メッセージ上にMeigetsu
                        IDのユーザーIDをご入力いただき、下記のデータを添付して送信して下さい。当社で確認後、登録の補正等を行った後に本人確認レベルを「簡易的な本人確認等を実施」に変更致します。
                    </p>
                    <ol>
                        <li>
                            <p>
                                公的機関が発行した顔写真付き身分証明書の写真（表面と裏面。ただし、マイナンバーカードの場合は表面のみ）
                            </p>
                        </li>
                        <li>
                            <p>本人確認書類を持った状態での自撮り写真</p>
                        </li>
                    </ol>
                    <p>写真の撮影の際には下記の条件を満たしていることをご確認下さい。</p>
                    <ul>
                        <li>
                            <p>写真内にご自身の顔と顔写真付き身分証明書が同時に映っていること（１）</p>
                        </li>
                        <li>
                            <p>証明書の文字、顔写真が明確に判別できる（１、２共通）</p>
                        </li>
                        <li>
                            <p>顔は正面を向いて、無帽で髪が目にかかっていない（２）</p>
                        </li>
                        <li>
                            <p>他の人が写り込んでいない（２）</p>
                        </li>
                    </ul>
                    <p>
                        自撮り写真の撮影が難しい場合は、上記条件を満たしていれば他の方に写真を撮影頂いても構いません。
                    </p>
                    <p>
                        なお、厳格な本人確認等をご希望の場合は住民票記載事項証明書のご提出が必要になりますので、メールにその旨の記載をお願い致します。
                    </p>
                </div>
            </section>
            <section>
                <div className="box_title">
                    <a href="mailto:info@mail.meigetsu.jp?subject=本人確認・年齢確認情報更新のお願い">
                        本人確認・年齢確認情報更新
                    </a>
                </div>
                <div className="box_content">
                    <p>
                        過去に本人確認、年齢確認のお願いをし、本人確認ステータスが「未登録」、「自己申告」のいずれでもない方はメールでのお手続きが必要になります。
                    </p>
                    <p>メッセージ上にMeigetsu IDのユーザーIDをご入力いただき、下記のデータを添付して送信して下さい。</p>
                    <ol>
                        <li>
                            <p>
                                公的機関が発行した顔写真付き身分証明書の写真（表面と裏面。ただし、マイナンバーカードの場合は表面のみ）
                            </p>
                        </li>
                        <li>
                            <p>本人確認書類を持った状態での自撮り写真</p>
                        </li>
                    </ol>
                    <p>
                        なお、ステータスが「厳格な本人確認等を実施」になっている方には改めて登録されているメール宛てに住民票記載事項証明書の書式を送付致します。
                    </p>
                </div>
            </section>
        </div>
    );
};
