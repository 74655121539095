import React from 'react';
import { useParams } from 'react-router-dom';
import toastr from 'toastr';
import '../../css/signup.css';

export const SignUpItem = () => {
    const { confirmId } = useParams();
    if (confirmId == null) {
        location.href = '/signup';
        return <p></p>;
    }
    const [state, setState] = React.useState({
        input: { user_id: '', name_or_corp_number: '', password: '', password_retype: '' },
        control: { active_form: '' },
    });
    const CreateForm = LabelForNameSection =>
        React.createElement('dl', null, [
            React.createElement('dt', null, 'ユーザーＩＤ'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'text',
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.input.user_id = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement('dt', null, LabelForNameSection),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'text',
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.input.name_or_corp_number = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement('dt', null, 'パスワード'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'password',
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.input.password = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement('dt', null, 'パスワード（確認）'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'password',
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.input.password_retype = e.target.value;
                        setState(Current);
                    },
                })
            ),
        ]);
    const ForPersonal = CreateForm('名前');
    const ForCorp = CreateForm('法人番号または商業登記簿上の会社法人等番号');
    const RequestBody = {
        get Personal() {
            return {
                user_id: state.input.user_id,
                name: state.input.name_or_corp_number,
                password: state.input.password,
            };
        },
        get Corp() {
            return {
                user_id: state.input.user_id,
                corp_number: state.input.name_or_corp_number,
                password: state.input.password,
            };
        },
    };
    const CreateRequest = RequestBody => {
        const AutoSignIn = async () => {
            /**
             * @type {{ redirect_url: string, code_verify: string }}
             */
            const AuthInfo = await fetch('https://idportal.meigetsu.jp/app/auth', { credentials: 'omit' }).then(
                response => response.json()
            );
            sessionStorage.setItem('cdv', AuthInfo.code_verify);
            const Queries = new URLSearchParams(AuthInfo.redirect_url.split('?')[1]);
            await fetch('https://idportal.meigetsu.jp/app/signin', {
                method: 'POST',
                credentials: 'omit',
                body: JSON.stringify({
                    inputed_id: state.input.user_id,
                    inputed_pw: state.input.password,
                    auth_id: Queries.get('auth_id'),
                }),
                headers: { 'Content-Type': 'application/json' },
            })
                .then(response => response.text())
                .then(callbackUrl => {
                    location.href = callbackUrl;
                });
        };
        if (state.input.password !== state.input.password_retype) {
            toastr['error']('パスワードが不一致です');
            return;
        }
        return fetch(`https://idportal.meigetsu.jp/app/signup/${confirmId}`, {
            method: 'POST',
            credentials: 'omit',
            body: JSON.stringify(RequestBody),
            headers: { 'Content-Type': 'application/json' },
        }).then(response => {
            if (response.status === 201) {
                toastr['success']('登録完了しました。ポータルサイトへの自動サインインを実行しますのでお待ち下さい。');
                return AutoSignIn();
            } else {
                toastr['error']('登録に失敗しました。');
                return Promise.resolve();
            }
        });
    };
    const RequestButton = React.createElement('input', {
        type: 'submit',
        value: 'サインアップ',
        onClick: () => {
            switch (state.control.active_form) {
                case 'personal':
                    return CreateRequest(RequestBody.Personal);
                case 'corp':
                    return CreateRequest(RequestBody.Corp);
                default:
                    return;
            }
        },
    });
    const GetInputForm = () => {
        switch (state.control.active_form) {
            case 'personal':
                return ForPersonal;
            case 'corp':
                return ForCorp;
            default:
                return React.createElement('div');
        }
    };
    return React.createElement('div', { className: 'container' }, [
        React.createElement('ul', { className: 'selection_button' }, [
            React.createElement(
                'li',
                null,
                React.createElement('input', {
                    type: 'button',
                    value: '個人のアカウントを作る',
                    onClick: () => {
                        const Current = Object.assign({}, state);
                        Current.control.active_form = 'personal';
                        setState(Current);
                    },
                })
            ),
            React.createElement(
                'li',
                null,
                React.createElement('input', {
                    type: 'button',
                    value: '法人のアカウントを作る',
                    onClick: () => {
                        const Current = Object.assign({}, state);
                        Current.control.active_form = 'corp';
                        setState(Current);
                    },
                })
            ),
        ]),
        React.createElement(
            'div',
            {
                style: {
                    visibility:
                        state.control.active_form === 'personal' || state.control.active_form === 'corp'
                            ? 'visible'
                            : 'hidden',
                },
            },
            [GetInputForm(), RequestButton]
        ),
    ]);
};
