import React from 'react';
import MarkDown from 'react-markdown';

export const APITermItem = () => {
    const [term, setTerm] = React.useState('');
    React.useEffect(() => {
        if (term !== '') return;
        fetch('/resources/apiterm.md')
            .then(response => response.text())
            .then(text => setTerm(text));
    });
    return (
        <div className="container">
            <MarkDown>{term}</MarkDown>
        </div>
    );
};
