import { setTokenCookies, getTokenFromCookies } from './cookie.js';

export const TokenManager = {
    inspection: async () => {
        const data = TokenManager.get();
        if (data.accessToken == null) {
            console.log('Access token is expired');
            if (data.refreshToken == null) {
                console.log('Refresh token is expired');
                return;
            }
            await fetch('https://idportal.meigetsu.jp/api/v2/auth/token', {
                method: 'POST',
                body: JSON.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: data.refreshToken,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(res => {
                    if (res.status !== 200) return res.text().then(txt => Promise.reject(new Error(txt)));
                    return res.json();
                })
                .then(data => {
                    return {
                        token_type: data.token_type,
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        expires_at: {
                            access_token: new Date(data.expires_at.access_token),
                            refresh_token: new Date(data.expires_at.refresh_token),
                        },
                    };
                })
                .then(record => {
                    TokenManager.set(record);
                })
                .catch(er => console.error(er.message));
        }
    },
    get: () => getTokenFromCookies(),
    /**
     *
     * @param {{ token_type: string, access_token: string, refresh_token: string, expires_at: { access_token: Date, refresh_token: Date }}} data
     */
    set: data => {
        setTokenCookies(
            data.access_token,
            data.refresh_token,
            data.expires_at.access_token,
            data.expires_at.refresh_token
        );
    },
};
