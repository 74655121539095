import React from 'react';
import { TokenManager } from '../../process/TokenManager.js';
import { removeTokens } from '../../process/cookie.js';

export const SignOutItem = () => {
    React.useEffect(() => {
        TokenManager.inspection().then(() => {
            const Token = TokenManager.get();
            removeTokens(); // この位置で呼ばないとリフレッシュトークンが消えない
            if (Token.accessToken == null) return Promise.resolve();
            return fetch('https://idportal.meigetsu.jp/api/v2/auth', {
                method: 'DELETE',
                credentials: 'omit',
                headers: {
                    Authorization: `Bearer ${Token.accessToken}`,
                },
            });
        });
    });
    return (
        <div className="container">
            <p>サインアウトが完了しました。</p>
            <p>再度ご利用される場合はサインインして下さい。</p>
        </div>
    );
};
