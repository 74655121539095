import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import toastr from 'toastr';
import './css/main.css';
import 'bootstrap';

import { HeaderItem } from './main/header.jsx';
import { IndexItem } from './main/index.jsx';
import { CookiePolicyItem } from './main/CookiePolicy.jsx';
import { ReadyItem } from './main/auth/ready.jsx';
import { CallbackItem } from './main/auth/callback.jsx';
import { OAuthItem } from './main/auth/oauth.jsx';
import { UserProfileItem } from './main/user/index.jsx';
import { NotFoundItem } from './main/404.jsx';
import { UpdateConfirmItem } from './main/user/updateConfirm.jsx';
import { EditPageItem } from './main/user/edit.jsx';
import { CivilLawItem } from './main/CivilLaw.jsx';
import { TermItem } from './main/term.jsx';
import { NotificationItem } from './main/Notification.jsx';
import { PreEntryItem } from './main/auth/PreEntry.jsx';
import { SignOutItem } from './main/auth/signout.jsx';
import { SignUpItem } from './main/auth/signup.jsx';
import { ContactItem } from './main/contact.jsx';
import { AfterContactItem } from './main/afterContact.jsx';
import { SpecItem } from './main/api/spec.jsx';
import { OAuthURLGeneratorItem } from './main/api/urlgen.jsx';
import { ApplicationListViewItem } from './main/application/view.jsx';
import { AppCreateItem } from './main/application/create.jsx';
import { AppEditItem } from './main/application/edit.jsx';
import { APITermItem } from './main/api/term.jsx';

toastr.options = {
    timeOut: 3000, // 3秒
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-bottom-right',
    preventDuplicates: false,
    showDuration: '300',
    hideDuration: '1000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
};

const header = ReactDOM.createRoot(document.getElementById('header'));
const main = ReactDOM.createRoot(document.getElementById('content'));
const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" index element={<IndexItem />} />
                <Route path="/cookie_policy" element={<CookiePolicyItem />} />
                <Route path="/ready" element={<ReadyItem />} />
                <Route path="/callback" element={<CallbackItem />} />
                <Route path="/oauth" element={<OAuthItem />} />
                <Route path="/user" element={<UserProfileItem />} />
                <Route path="/signup" element={<PreEntryItem />} />
                <Route path="/signup/:confirmId" element={<SignUpItem />} />
                <Route path="/signout" element={<SignOutItem />} />
                <Route path="/edit" element={<UpdateConfirmItem />} />
                <Route path="/edit/:confirmId" element={<EditPageItem />} />
                <Route path="/civil_law" element={<CivilLawItem />} />
                <Route path="/term" element={<TermItem />} />
                <Route path="/notification" element={<NotificationItem />} />
                <Route path="/contact" element={<ContactItem />} />
                <Route path="/contact/:contactId" element={<AfterContactItem />} />
                <Route path="/application" element={<ApplicationListViewItem />} />
                <Route path="/application/new" element={<AppCreateItem />} />
                <Route path="/application/:appId" element={<AppEditItem />} />
                <Route path="/api/spec" element={<SpecItem />} />
                <Route path="/api/urlgen" element={<OAuthURLGeneratorItem />} />
                <Route path="/api/term" element={<APITermItem />} />
                <Route path="*" element={<NotFoundItem />} />
            </Routes>
        </Router>
    );
};

header.render(
    <React.StrictMode>
        <HeaderItem />
    </React.StrictMode>
);
main.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
