/**
 *
 * @param {string} accessToken
 * @param {string} refreshToken
 * @param {Date} RefreshTokenExpire
 */
export function setTokenCookies(accessToken, refreshToken, AccessTokenExpire, RefreshTokenExpire) {
    document.cookie = 'mainkey=' + btoa(accessToken) + ' expires=' + AccessTokenExpire.toUTCString() + '; path=/';
    document.cookie = 'subkey=' + btoa(refreshToken) + '; expires=' + RefreshTokenExpire.toUTCString() + '; path=/';
}

export function getTokenFromCookies() {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    const accessToken = cookies.find(cookie => cookie.startsWith('mainkey='));
    const refreshToken = cookies.find(cookie => cookie.startsWith('subkey='));
    return {
        accessToken: accessToken ? atob(accessToken.split('=')[1]) : null,
        refreshToken: refreshToken ? atob(refreshToken.split('=')[1]) : null,
    };
}

export function removeTokens() {
    document.cookie = 'mainkey=;max-age=0';
    document.cookie = 'subkey=;max-age=0';
}
