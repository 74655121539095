import React from 'react';
import { useParams } from 'react-router-dom';
import { TokenManager } from '../../process/TokenManager.js';
import accountinfo from '../../resources/accountinfos.json';
import prefectureJson from '../../resources/prefectures.json';
import toastr from 'toastr';
import '../../css/box.css';
import '../../css/mail.css';
const postCodeReg = /^[0-9]{3}-?[0-9]{4}$/;

/**
 *
 * @param {Date|null} Dt
 * @returns {string}
 */
const convertDateText = Dt => {
    if (Dt == null) return convertDateText(new Date());
    const Result = `${Dt.getFullYear()}-${(Dt.getMonth() + 1).toString().padStart(2, '0')}-${Dt.getDate()
        .toString()
        .padStart(2, '0')}`;
    return Result;
};

export const EditPageItem = () => {
    const { confirmId } = useParams();
    if (confirmId == null) {
        location.href = '/user';
        return <p></p>;
    }
    const [state, setState] = React.useState({
        done: false,
        mailConfirmId: '',
        access_token: '',
        /**
         * @type: { first: string, retype: string }
         */
        password: { first: '', retype: '' },
        record: {
            user_id: '',
            mailaddress: '',
            name: '',
            account_type: '',
            /**
             * @type { Date | null }
             */
            created_at: null,
            personality_classification: 0,
            /**
             * @type {{ first_name: string, family_name: string, prefecture: string, city: string, address: string, birthday: Date | null, gender: string, user_check_status: string }}
             */
            personal: {
                first_name: '',
                family_name: '',
                prefecture: '',
                city: '',
                address: '',
                birthday: null,
                gender: '',
                user_check_status: 0,
            },
        },
    });
    React.useEffect(() => {
        if (state.done) return;
        const AfterInspection = async () => {
            const Token = TokenManager.get();
            if (Token.accessToken == null) {
                location.href = '/ready';
                return Promise.resolve();
            }
            await fetch('https://idportal.meigetsu.jp/api/v2/user?contain_personal=true', {
                method: 'GET',
                credentials: 'omit',
                headers: { Authorization: `Bearer ${Token.accessToken}` },
            })
                .then(record => record.json())
                .then(json => {
                    const AccountRecord = {
                        user_id: json.user_id,
                        mailaddress: json.mailaddress,
                        name: json.name,
                        account_type: accountinfo.account_types[json.account_type],
                        created_at: new Date(json.created_at),
                        personality_classification: json.personality_classification,
                        personal:
                            json.personal == null
                                ? {
                                      first_name: '',
                                      family_name: '',
                                      prefecture: '',
                                      city: '',
                                      address: '',
                                      birthday: null,
                                      gender: '',
                                      user_check_status: accountinfo.check_levels[0],
                                  }
                                : {
                                      first_name: json.personal.first_name ?? '',
                                      family_name: json.personal.family_name ?? '',
                                      prefecture: json.personal.prefecture ?? '',
                                      city: json.personal.city ?? '',
                                      address: json.personal.address ?? '',
                                      birthday:
                                          json.personal.birthday == null ? null : new Date(json.personal.birthday),
                                      gender:
                                          json.personal.gender == null ? '' : accountinfo.genders[json.personal.gender],
                                      user_check_status: json.personal.check_level ?? 0,
                                  },
                    };
                    setState({
                        done: true,
                        access_token: Token.accessToken,
                        mailConfirmId: state.mailConfirmId,
                        password: state.password,
                        record: AccountRecord,
                    });
                });
        };
        TokenManager.inspection().then(() => AfterInspection());
    });
    if (!state.done)
        return React.createElement(
            'div',
            { className: 'container' },
            React.createElement('p', null, 'アカウント情報取得中...')
        );
    const Basic = [
        React.createElement('dl', null, [
            React.createElement('dt', null, 'ユーザーＩＤ'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'text',
                    value: state.record.user_id,
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.record.user_id = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement('dt', null, 'ユーザー名'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'text',
                    value: state.record.name,
                    readOnly: state.record.personality_classification === 1,
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.record.name = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement(
                'dd',
                null,
                state.record.personality_classification === 1
                    ? '※人格区分が法人のため、ユーザー名の変更はできません'
                    : ''
            ),
            React.createElement('dt', null, 'パスワード'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'password',
                    value: state.password.first,
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.password.first = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement('dt', null, 'パスワード（再確認）'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'password',
                    value: state.password.retype,
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.password.retype = e.target.value;
                        setState(Current);
                    },
                })
            ),
        ]),
        React.createElement('input', {
            type: 'submit',
            onClick: () => {
                if (
                    state.password.first.length + state.password.retype > 0 &&
                    state.password.first !== state.password.retype
                ) {
                    toastr['error']('パスワードが不一致です', 'エラー');
                } else {
                    fetch(`https://idportal.meigetsu.jp/api/v2/user/${confirmId}`, {
                        method: 'PATCH',
                        credentials: 'omit',
                        body: JSON.stringify({
                            user_id: state.record.user_id.length === 0 ? undefined : state.record.user_id,
                            name: state.record.name.length === 0 ? undefined : state.record.name,
                            password: state.password.first.length === 0 ? undefined : state.password.first,
                        }),
                        headers: {
                            Authorization: `Bearer ${state.access_token}`,
                            'Content-Type': 'application/json',
                        },
                    }).then(response => {
                        if (response.status === 200) toastr['success']('更新しました', '成功');
                        else
                            return response.text().then(val => {
                                toastr['error'](val, '失敗');
                            });
                    });
                }
            },
        }),
    ];
    const MailAddress = [
        React.createElement('dl', null, [
            React.createElement('dt', null, 'メールアドレス'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'email',
                    value: state.record.mailaddress,
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.record.mailaddress = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'submit',
                    value: '確認メール送信',
                    onClick: () => {
                        fetch(`https://idportal.meigetsu.jp/api/v2/user/${confirmId}/mailaddress`, {
                            method: 'PATCH',
                            credentials: 'omit',
                            body: state.record.mailaddress,
                            headers: {
                                Authorization: `Bearer ${state.access_token}`,
                                'Content-Type': 'application/json',
                            },
                        }).then(response => {
                            if (response.status === 200) toastr['success']('確認メールを送信しました', '成功');
                            else
                                return response.text().then(val => {
                                    toastr['error'](val, '失敗');
                                });
                        });
                    },
                })
            ),
            React.createElement('dt', null, '確認コード'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'text',
                    value: state.mailConfirmId,
                    onInput: e => {
                        const Current = Object.assign({}, state);
                        Current.mailConfirmId = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'submit',
                    value: '確認コード送信',
                    onClick: () => {
                        fetch(`https://idportal.meigetsu.jp/api/v2/user/${state.mailConfirmId}/mailaddress`, {
                            method: 'PATCH',
                            credentials: 'omit',
                            headers: {
                                Authorization: `Bearer ${state.access_token}`,
                                'Content-Type': 'application/json',
                            },
                        }).then(response => {
                            if (response.status === 200) toastr['success']('確認メールを送信しました', '成功');
                            else
                                return response.text().then(val => {
                                    toastr['error'](val, '失敗');
                                });
                        });
                    },
                })
            ),
        ]),
    ];
    const PersonalEditUnavailable = [
        React.createElement('p', { style: { color: 'red' } }, 'お客様の情報はここで変更することはできません。'),
        React.createElement(
            'p',
            { style: { color: 'red' } },
            '結婚や転居等で登録情報に変更がある場合は、「各種届出」から届出をお願い致します。'
        ),
    ];
    const Personal = [
        React.createElement(
            'p',
            { style: { color: 'red' } },
            'このフィールドは、個人情報の入力を行うフィールドです。制限行為能力者が入力する場合は、必ず法定代理人が監督の上で入力をお願い致します。'
        ),
        React.createElement(
            'p',
            null,
            React.createElement('a', { href: '/civil_law#person_with_limited_ability_to_act' }, '制限行為能力者とは？')
        ),
        React.createElement('dl', null, [
            React.createElement('dt', null, '名字'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'text',
                    placeholder: '山田',
                    value: state.record.personal.family_name,
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.record.personal.family_name = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement('dt', null, '名前'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'text',
                    placeholder: '太郎',
                    value: state.record.personal.first_name,
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.record.personal.first_name = e.target.value;
                        setState(Current);
                    },
                })
            ),
            React.createElement('dt', null, '住所'),
            React.createElement(
                'dd',
                null,
                React.createElement('dl', null, [
                    React.createElement('dt', null, '郵便番号'),
                    React.createElement(
                        'dd',
                        null,
                        React.createElement('input', {
                            type: 'text',
                            placeholder: 'ex: 107-0062 or 1070062',
                            onInput: e => {
                                if (!postCodeReg.test(e.target.value)) return;
                                fetch(`https://idportal.meigetsu.jp/api/v2/postcode?value=${e.target.value}`, {
                                    credentials: 'omit',
                                })
                                    .then(response => {
                                        if (response.status !== 200)
                                            return Promise.reject(
                                                new Error(`${e.target.value}: post code is not found on database`)
                                            );
                                        return response.json();
                                    })
                                    .then(j => {
                                        const Current = Object.assign({}, state);
                                        Current.record.personal.prefecture = j.prefecture;
                                        Current.record.personal.city = j.city;
                                        Current.record.personal.address = j.address;
                                        setState(Current);
                                    })
                                    .catch(er => toastr['error'](er.message));
                            },
                        })
                    ),
                    React.createElement('dt', null, '都道府県'),
                    React.createElement(
                        'dd',
                        null,
                        React.createElement(
                            'select',
                            {
                                onChange: e => {
                                    const Current = Object.assign({}, state);
                                    Current.record.personal.prefecture = e.target.value;
                                    setState(Current);
                                },
                                value: state.record.personal.prefecture,
                            },
                            prefectureJson.prefectures.map(i => React.createElement('option', { value: i }, i))
                        )
                    ),
                    React.createElement('dt', null, '市区町村'),
                    React.createElement(
                        'dd',
                        null,
                        React.createElement('input', {
                            type: 'text',
                            style: {
                                width: '20%',
                            },
                            value: state.record.personal.city,
                            onChange: e => {
                                const Current = Object.assign({}, state);
                                Current.record.personal.city = e.target.value;
                                setState(Current);
                            },
                        })
                    ),
                    React.createElement('dt', null, '町名・番地等'),
                    React.createElement(
                        'dd',
                        null,
                        React.createElement('input', {
                            type: 'text',
                            style: {
                                width: '40%',
                            },
                            value: state.record.personal.address,
                            onChange: e => {
                                const Current = Object.assign({}, state);
                                Current.record.personal.address = e.target.value;
                                setState(Current);
                            },
                        })
                    ),
                ])
            ),
            React.createElement('dt', null, '誕生日'),
            React.createElement(
                'dd',
                null,
                React.createElement('input', {
                    type: 'date',
                    value: convertDateText(state.record.personal.birthday),
                    onChange: e => {
                        const Current = Object.assign({}, state);
                        Current.record.personal.birthday = new Date(e.target.value);
                        setState(Current);
                    },
                })
            ),
            React.createElement('dt', null, '性別'),
            React.createElement(
                'dd',
                null,
                React.createElement(
                    'select',
                    {
                        onChange: e => {
                            const Current = Object.assign({}, state);
                            Current.record.personal.gender = e.target.value;
                            setState(Current);
                        },
                        value: state.record.personal.gender,
                    },
                    accountinfo.genders.map(i => React.createElement('option', { value: i }, i))
                )
            ),
        ]),
        React.createElement('input', {
            type: 'submit',
            value: '更新',
            onClick: () => {
                /**
                 *
                 * @param {string | null} val
                 */
                const checkParam = val => (val == null || val.length === 0 ? undefined : val);
                /**
                 *
                 * @param {Date} val
                 */
                const checkDate = val => {
                    if (val == null) return undefined;
                    return `${val.getFullYear()}/${(val.getMonth() + 1).toString().padStart(2, '0')}/${val.getDate().toString().padStart(2, '0')}`;
                };
                const RequestBody = {
                    family_name: checkParam(state.record.personal.family_name),
                    first_name: checkParam(state.record.personal.first_name),
                    prefecture: checkParam(state.record.personal.prefecture),
                    city: checkParam(state.record.personal.city),
                    address: checkParam(state.record.personal.address),
                    birthday: checkDate(state.record.personal.birthday),
                    gender: checkParam(state.record.personal.gender),
                };
                fetch(`https://idportal.meigetsu.jp/api/v2/user/mpim/${confirmId}`, {
                    method: 'PATCH',
                    credentials: 'omit',
                    body: JSON.stringify(RequestBody),
                    headers: {
                        Authorization: `Bearer ${state.access_token}`,
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        if (response.status === 200) toastr['success']('更新成功しました', '成功');
                        else return response.text().then(txt => Promise.reject(new Error(txt)));
                    })
                    .catch(er => {
                        toastr['error'](er.message, '失敗');
                    });
            },
        }),
    ];

    const Forms = [
        React.createElement('section', null, [
            React.createElement('div', { className: 'box_title' }, 'メールアドレス変更'),
            React.createElement('div', { className: 'box_content' }, MailAddress),
        ]),
        React.createElement('section', null, [
            React.createElement('div', { className: 'box_title' }, '基本データ更新'),
            React.createElement('div', { className: 'box_content' }, Basic),
        ]),
    ];
    if (state.record.personality_classification === 0) {
        Forms.push(
            React.createElement('section', null, [
                React.createElement('div', { className: 'box_title' }, '個人情報更新'),
                React.createElement(
                    'div',
                    { className: 'box_content' },
                    state.record.personal.user_check_status > 1 ? PersonalEditUnavailable : Personal
                ),
            ])
        );
    }
    return React.createElement('div', { className: 'container' }, Forms);
};
