import React from 'react';

export const CookiePolicyItem = () => {
    return (
        <div className="container">
            <h1>クッキーポリシー</h1>
            <p>
                明月（以下、「当社」といいます。）は、Meigetsu
                IDポータルサイト（以下、本サイト）においてお客様のアカウントセッション管理の目的のため、クッキーを使用致します。
            </p>
            <section>
                <h3>クッキーについて</h3>
                <ul>
                    <li>
                        クッキーとはお客様のウェブサイト閲覧情報を、そのお客様のコンピューター（PCやスマートフォン、タブレットなどインターネット接続可能な機器）に記憶させる機能のことです。
                    </li>
                    <li>
                        クッキーには、当社によって設定されるもの（ファーストパーティークッキー）と、当社と提携する第三者によって設定されるもの（サードパーティークッキー）があります。
                    </li>
                </ul>
            </section>
            <section>
                <h3>クッキーの利用目的</h3>
                <ul>
                    <li>
                        当社では、クッキーを、お客様がウェブサイトを閲覧する際に同じ情報を繰り返し入力することがなくなるなど、お客様の利便性向上のために使用しています。
                    </li>
                    <li>
                        当社では、上記記載以外の目的においてクッキーを使用することはありません。また、クッキーに保存された情報を本サイトのサービス提供以外の目的で使用することはありません。
                    </li>
                </ul>
            </section>
            <section>
                <h3>個人情報の取り扱いについて</h3>
                <p>
                    個人情報の取り扱いについては、当社
                    <a href="https://www.meigetsu.jp/privacy.html" target="blank">
                        プライバシーポリシー
                    </a>
                    をご確認下さい。
                </p>
            </section>
            <section>
                <h3>クッキーの拒否について</h3>
                <p>
                    本サイトではセッション管理のためにクッキーを使用しておりますので、本サイトのご利用にあたっては拒否をしないようお願い致します。
                </p>
            </section>
        </div>
    );
};
