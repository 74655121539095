import React from 'react';
import signin from '../resources/signin.png';
import signup from '../resources/signup.png';
import '../css/index.css';

export const IndexItem = () => {
    return (
        <div className="container">
            <h3>Meigetsu IDへようこそ</h3>
            <p>Meigetsu IDは、明月が提供するゲームおよびサービスの統合アカウントです。</p>
            <section>
                <h4>Meigetsu IDの特徴</h4>
                <ul>
                    <li>【全てのサービスへのアクセス】明月が提供するゲーム、サービスの全てを利用することができます</li>
                    <li>
                        【年齢フィルタリング機能】年齢に応じて遊べるゲーム、利用できるサービスを制限することができます
                    </li>
                    <li>
                        【サードパーティーアプリケーション連携】ご自身で新しくID機能の実装を行わなくても、Meigetsu
                        IDと連携を行うだけでＯＫ！
                    </li>
                    <li>
                        【個人情報保護に関する厳格審査】個人情報にアクセスしたいアプリケーション開発者に対して厳格な審査を行います
                    </li>
                </ul>
                <p>※一部のページは現在テスト中であり、非公開となっております。公開までしばらくお待ち下さい。</p>
                <p>
                    ※年齢フィルタリング機能は、ゲームやサービスがそれぞれの機能に対応していない場合は提供されませんのでご注意下さい。
                </p>
                <p>
                    ※年齢レーティング設定が18歳以上になっているものをご利用の際には、予め年齢確認書類の提出が必要になります
                </p>
            </section>
            <section>
                <h3>本ページについて</h3>
                <p>本サイトは、JavaScriptとCookieが使用されております。</p>
                <p>無効にされている場合、システムが正常に動作しませんので必ず有効化して下さい。</p>
            </section>
            <section>
                <div id="signin">
                    <section>
                        <a href="/ready">
                            <img src={signin} />
                        </a>
                    </section>
                    <section>
                        <a href="/signup">
                            <img src={signup} />
                        </a>
                    </section>
                </div>
            </section>
        </div>
    );
};
