import React from 'react';
import toastr from 'toastr';
import '../../css/authorization.css';

export const OAuthItem = () => {
    const QueryParams = new URLSearchParams(location.search);
    const AuthID = QueryParams.get('auth_id');
    if (AuthID == null) {
        location.href = 'https://idportal.meigetsu.jp/';
        return <div></div>;
    }
    const [state, setState] = React.useState({
        input_record: { inputed_id: '', inputed_pw: '' },
        oauth_app: null,
    });

    const AppInfoItem = () => {
        React.useEffect(() => {
            if (state.oauth_app !== null) return;
            fetch(`https://idportal.meigetsu.jp/api/v2/auth/signin/${AuthID}`, { credentials: 'omit' })
                .then(response => {
                    if (response.status !== 200) return Promise.reject();
                    return response.json();
                })
                .then(json => {
                    const CurrentState = Object.assign({}, state);
                    CurrentState.oauth_app = json;
                    setState(CurrentState);
                })
                .catch(er => {
                    console.error(er);
                });
        });
        if (state.oauth_app === null)
            return React.createElement('div', { id: 'appinfo' }, React.createElement('p', null, '読み込み中'));
        const AppInfo = React.createElement('dl', null, [
            React.createElement('dt', null, React.createElement('label', null, 'アプリケーションの名称')),
            React.createElement('dd', null, state.oauth_app.name),
            React.createElement('dt', null, React.createElement('label', null, 'アプリケーションの説明')),
            React.createElement('dd', null, state.oauth_app.description ?? '説明がありません'),
            React.createElement('dt', null, React.createElement('label', null, 'アプリケーションのデベロッパー')),
            React.createElement('dd', null, state.oauth_app.developer),
            React.createElement('dt', null, React.createElement('label', null, 'プライバシーポリシー')),
            React.createElement(
                'dd',
                null,
                React.createElement('a', { href: state.oauth_app.privacy }, state.oauth_app.privacy)
            ),
            React.createElement('dt', null, React.createElement('label', null, '利用規約')),
            React.createElement(
                'dd',
                null,
                state.oauth_app.term
                    ? React.createElement('a', { href: state.oauth_app.term }, state.oauth_app.term)
                    : '登録されていません'
            ),
        ]);
        const ScopeInfo = React.createElement('dl', null, [
            React.createElement('dt', null, React.createElement('label', null, '許可する項目')),
            React.createElement(
                'dd',
                null,
                React.createElement(
                    'ul',
                    null,
                    state.oauth_app.scope.map(s => React.createElement('li', null, s))
                )
            ),
        ]);
        return React.createElement('div', { id: 'appinfo' }, [AppInfo, ScopeInfo]);
    };
    const SignInForm = () => {
        const SignInClickAction = async () => {
            const RequestBody = {
                ...state.input_record,
                auth_id: AuthID,
            };
            await fetch('https://idportal.meigetsu.jp/app/signin', {
                method: 'POST',
                credentials: 'omit',
                body: JSON.stringify(RequestBody),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.status === 404) return Promise.reject(new Error('セッションがタイムアウトしました'));
                    else if (response.status !== 200)
                        return response.text().then(txt => Promise.reject(new Error(txt)));
                    return response.text();
                })
                .then(txt => {
                    location.href = txt;
                })
                .catch(er => {
                    toastr['error'](er.message, '失敗');
                });
        };
        const IDInputForm = React.createElement('input', {
            type: 'text',
            onInput: e => {
                const CurrentState = state;
                CurrentState.input_record.inputed_id = e.target.value;
                setState(CurrentState);
            },
        });
        const PassInputForm = React.createElement('input', {
            type: 'password',
            onInput: e => {
                const CurrentState = state;
                CurrentState.input_record.inputed_pw = e.target.value;
                setState(CurrentState);
            },
        });
        const SigninButton = React.createElement('input', {
            type: 'submit',
            text: 'サインイン',
            onClick: SignInClickAction,
        });
        return React.createElement('div', { id: 'signin_form' }, [
            React.createElement('dl', null, [
                React.createElement(
                    'dt',
                    null,
                    React.createElement('label', null, '整理番号、法人番号、ユーザーＩＤまたはメールアドレス')
                ),
                React.createElement('dd', null, IDInputForm),
                React.createElement('dt', null, React.createElement('label', null, 'パスワード')),
                React.createElement('dd', null, PassInputForm),
            ]),
            SigninButton,
        ]);
    };
    const Message = React.createElement('p', null, 'アプリケーションに対して操作を許可する場合はサインインして下さい');
    const TimeLimitMessage = React.createElement(
        'p',
        null,
        '※３分以内にサインインが行われない場合、本セッションでのサインインはできません。'
    );
    return React.createElement('div', { className: 'container' }, [
        Message,
        SignInForm(),
        TimeLimitMessage,
        AppInfoItem(),
    ]);
};
