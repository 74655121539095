import React from 'react';

export const CivilLawItem = () => {
    return (
        <div className="container">
            <h2>民法上の用語の説明</h2>
            <p>※よいこはおとうさんとおかあさんによんでもらってね</p>
            <section id="person_with_limited_ability_to_act">
                <div className="box_title">制限行為能力者</div>
                <div className="box_content">
                    <p>
                        制限行為能力者とは、法律上、法律行為の一部または全部において自己の意思決定能力が制限された人のことを指します。
                    </p>
                    <p>具体的には</p>
                    <ul>
                        <li>未成年者</li>
                        <li>成年被後見人</li>
                        <li>被保佐人</li>
                        <li>被補助人</li>
                    </ul>
                    <p>を指します。</p>
                    <p>
                        明月では、これらの方によるMeigetsu IDおよび各サービスのご利用にあたっては
                        <a href="#legal_representative">法定代理人</a>の監督の下で行うことをお願いしております。
                    </p>
                    <div className="box_title">未成年者</div>
                    <div className="box_content">
                        <p>未成年者とは、民法第４条に既定する年齢に達していない者を指します。</p>
                        <p>2023年4月1日現在では18歳に満たない者を未成年者としています。</p>
                        <p>
                            また、明月ではこの年齢に達していた場合であっても、高等学校およびこれに準ずる学校に通っている者についても未成年者として扱います。
                        </p>
                    </div>
                    <div className="box_title">成年被後見人</div>
                    <div className="box_content">
                        <p>
                            成年被後見人とは、精神上の障害により事理弁識能力（ある物事の実態やその考えられる結果などについて理解でき、自ら法的に有効な意思表示ができる能力）が欠如しているとして、家庭裁判所から後見開始の審判を受けた人を指します。
                        </p>
                    </div>
                    <div className="box_title">被保佐人</div>
                    <div className="box_content">
                        <p>
                            被保佐人とは、精神上の障害により事理弁識能力が著しく不十分であり、家庭裁判所保佐開始の審判を受けた者を指します。
                        </p>
                    </div>
                    <div className="box_title">被補助人</div>
                    <div className="box_content">
                        <p>
                            被補助人とは、精神上の障害により事理弁識能力が不十分であり、家庭裁判所から補助開始の審判を受けた者を指します。
                        </p>
                    </div>
                </div>
            </section>
            <section id="legal_representative">
                <div className="box_title">法定代理人</div>
                <div className="box_content">
                    <p>
                        法定代理人とは、法律上、<a href="#person_with_limited_ability_to_act">制限行為能力者</a>
                        の権利や利益を代表し、法的な手続きを行う権限を持つ人のことを指します。法定代理人は、法律や裁判所の命令によって指定されます。
                    </p>
                    <div className="box_title">未成年者の法定代理人</div>
                    <div className="box_content">
                        <p>未成年者の法定代理人は、原則として親権者（父、母）となります。</p>
                    </div>
                    <div className="box_title">未成年者以外の法定代理人</div>
                    <div className="box_content">
                        <p>家庭裁判所によって任命された者</p>
                    </div>
                </div>
            </section>
            <section id="cancel_due_to_limited_ability_to_act">
                <div className="box_title">制限行為能力者であることを理由とした手続きの取り消し等について</div>
                <div className="box_content">
                    <p>
                        <a href="person_with_limited_ability_to_act">制限行為能力者</a>が
                        <a href="legal_representative">法定代理人</a>
                        の同意なく行った法律行為については、民法の規定により取り消すことができます。
                    </p>
                    <p>
                        明月では、これらの行為の取り消し等の手続のため、制限行為能力者がMeigetsu
                        IDの登録を行った際には、法定代理人の方の連絡先の確認をさせていただいております。
                    </p>
                    <p>
                        制限行為能力者がMeigetsu
                        IDにご登録の際には、お手数ですが、下記の事項を「各種届出」より届出をお願い致します。
                    </p>
                    <ul>
                        <li>当該制限行為能力者のMeigetsu IDのユーザーID</li>
                        <li>登録時に使用したメールアドレス</li>
                        <li>法定代理人の氏名</li>
                        <li>
                            法定代理人のメールアドレスまたは日中連絡のつく電話番号（原則、メールアドレスでお願い致します）
                        </li>
                    </ul>
                    <p>連絡先につきましては、複数ご記入いただいても構いません。</p>
                    <p>
                        個人情報の取り扱いにつきましては、当社
                        <a href="https://www.meigetsu.jp/privacy.html">プライバシーポリシー</a>をご確認下さい。
                    </p>
                    <p>なお、当社からの連絡に使用される電話番号およびメールアドレスは下記の通りです。</p>
                    <p>TEL: 03-6161-6038</p>
                    <p>Mail: info@mail.meigetsu.jp</p>
                </div>
            </section>
        </div>
    );
};
