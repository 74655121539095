import React from 'react';
import toastr from 'toastr';
import { TokenManager } from '../../process/TokenManager.js';

export const PreEntryItem = () => {
    const Record = TokenManager.get();
    if (Record.accessToken !== null || Record.refreshToken !== null) {
        location.href = '/user';
        return React.createElement('div');
    }
    const [mailaddress, setMailAddress] = React.useState('');
    return React.createElement('div', { className: 'container' }, [
        React.createElement('section', null, [
            React.createElement('p', null, 'このたびはMeigetsu IDへのご登録ありがとうございます。'),
            React.createElement(
                'p',
                null,
                React.createElement('a', { href: '/term', target: 'blank' }, '利用規約'),
                'と',
                React.createElement(
                    'a',
                    { href: 'https://www.meigetsu.jp/privacy.html', target: 'blank' },
                    '当社プライバシーポリシー'
                ),
                'をご確認の上、メールアドレスのご入力をお願い致します。'
            ),
            React.createElement(
                'p',
                null,
                'なお、本登録を完了した地点で利用規約とプライバシーポリシーに同意したものをみなしますのでご注意下さい。'
            ),
            React.createElement(
                'p',
                null,
                'また、当社はMeigetsu IDのサービスのご提供にあたり、お客様の個人情報を取り扱う際にはご登録いただいたメールアドレス宛にお客様の個人情報を含めたメールを送信致しますので、フリーメールアドレスのご利用はお控えいただきますようお願い致します。'
            ),
        ]),
        React.createElement(
            'section',
            null,
            React.createElement('dl', null, [
                React.createElement('dt', null, React.createElement('label', null, 'メールアドレス')),
                React.createElement(
                    'dd',
                    null,
                    React.createElement('input', {
                        type: 'email',
                        onInput: e => {
                            setMailAddress(e.target.value);
                        },
                    })
                ),
                React.createElement(
                    'dd',
                    null,
                    React.createElement('input', {
                        type: 'submit',
                        onClick: () => {
                            fetch('https://idportal.meigetsu.jp/app/signup', {
                                method: 'POST',
                                credentials: 'omit',
                                body: mailaddress,
                            }).then(response => {
                                switch (response.status) {
                                    case 200:
                                        toastr['success']('入力したメールアドレス宛にメールをお送り致しました。');
                                        break;
                                    case 400:
                                        toastr['error']('このメールアドレスは使用できません');
                                        break;
                                    default:
                                        toastr['error']('予期しないエラーが発生しました。');
                                        break;
                                }
                            });
                        },
                    })
                ),
            ])
        ),
    ]);
};
